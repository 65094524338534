.App {
  text-align: center;
}


.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.8vw + 0.8vh);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

