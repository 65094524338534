html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike,
strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  font-family: Lato, Helvetica, Sans-serif, serif ;
  color: #404040;
  padding:0;
  border:0;
  outline:0;
  font-weight:inherit;
  font-style:inherit;
  vertical-align:baseline;
}

body {
  margin: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  position: -ms-device-fixed !important;
  overflow-y: visible;
  overflow-x: hidden;
  z-index: 1030;
}

.buttoncontainer {
  padding-top: 15vh;
}

.paragraph {
  min-width: 220px;
  margin-bottom: 0;
  font-weight: 400;;
  font-family: "Helvetica Neue", Helvetica,Arial, sans-serif;
  font-size: 18px;
  word-wrap: break-word;
  line-height: calc(0.8vh + 26px);
  text-align: justify;
  justify-items: left;
  margin-left:auto;
  margin-right:auto;
  align-items: center;
  justify-content: center;
  padding: 5vh 0 0;
  width:70%;
  max-width:70%;
}

.listContainer {
  line-height: calc(0.8vh + 26px);
  font-size: 18px;
  margin: 50px;
  font-family: inherit;
  text-align: justify;
  padding: 0;
}

.listItem {
  padding-bottom: 1.5vh;
}

.infobox{
  justify-content: center;
  border-width: calc(1px + 0.6vh);
  border-style: solid;
  margin-top: 5vh;
  margin-left: 15vw;
  margin-right: 15vw;
  border-color: #E40053;
}

.titleHeader {
  padding-top: calc(10vh + 88px)!important;
}

.title {
  font-family: Lato, Helvetica, Sans-serif, serif ;
  font-size: 38px ;
  font-weight: 700;
  line-height: 38px;
  padding-top: 15vh;
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 10px;
  clear: both;
  text-transform: uppercase;
  width: 75%;
}

.subtitle {
  font-family: Lato, Helvetica, Sans-serif, serif ;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  padding-top: 10vh;
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 10px;
  clear: both;
  text-transform: uppercase;
}

.button {
  font: inherit;
  font-size: calc(0.8vh + 0.8vw);
  text-align: center;
  color: #282c34;
  justify-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 50%;
  align-items: center;
  padding: calc(3vh + 3vw) calc(1.5vh + 1.5vw);
  width: 200px;
  height: 200px;
  line-height: 200px;
  border: calc(1px + 1vh) solid #282c34;
}

.navbar {
  margin: inherit;
  position: fixed;
  -webkit-box-shadow: 0 5px 11px 0 rgba(50,50,50,.08);
  box-shadow: 0 5px 11px 0 rgba(50,50,50,.08);
  border: 0;
  border-radius: 0!important;
  background: #ffffff;
  text-align: left;
  padding: 0 !important;
}

.navbar>.container, .navbar>.container-fluid {
  align-items: center;
  justify-content: space-between;
}

.nav-container {
  position: relative;
}

.navbar-brand {
  margin: 10px 0 10px 0;
  position: fixed;
  padding: 20px 80px 20px !important;
  float: left;
  display: block;
  background-image: url("./NH_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
}

.navbar-brand>a>img {
  width: auto;
  max-height: 60%;
}
img {
  max-width: 100%;
  height: auto;
}

.navbar-nav {
  flex-direction: row !important;
  display: flex!important;
  justify-content: space-evenly;
}

.container {
  display: flex!important;
  flex-direction: row!important;
  flex-wrap: nowrap!important;
}

.focus, .bottom-section {
  position: static;
  padding: 0;
  margin: 0;
}

.focus .html {
  position: fixed;
  margin: auto;
  padding: 0;
}


.nav-item{
  margin-right: 0;
  padding-right: 0;
  padding-left: 1vw;
  font-size: 1.3rem;
}

.nav-item:hover {
  cursor: pointer;
  color: #E40053;
}

.button:hover {
  color: #E40053;
  border-color: #E40053;
  text-decoration: none;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
}

.dark-footer {
  background-color: #404040!important;
}

.link {
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  cursor: pointer;
  color: #E40053 !important;
}

.note-link {
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  cursor: pointer;
  color: #E40053 !important;
}

.link:hover {
  cursor: pointer;
  color: #E40053;
  text-decoration: underline!important;
}

.footer-text {
  color: white;
  text-align: center;
}

.footer-text-bottom {
  padding-bottom: 2vh;
}

.footer-text-top {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.bottom-section {
  padding-bottom: 5vh;
}

.flex-container{
  display: flex;
  justify-content: flex-end;
}

.video {
  margin: 11vh 11vh 0;
  width:70%;
  max-width:70%;
  max-height: 50%;
}

.note {
  text-align: justify;
  font-size: 14px;
}

.darker-footer {
  background-color: #272727;
  padding: 2.5vh;
}

.no-padding {
  padding: 0;
}

.paragraph, .video, .nav-container {
  width: 100%;
  position: relative;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .paragraph, .video, .nav-container {
    margin-left: 15vw;
    margin-right: 15vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .paragraph, .video, .nav-container {
    margin-left: 15vw;
    margin-right: 15vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .video {
    max-height: 50% !important;
    height: 50% !important;
  }


  .paragraph, .video, .nav-container {
    margin-left: 15vw;
    margin-right: 15vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .nav-item {
    padding-bottom: .1em;
    padding-top: 0;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  .video {
    max-height: 50% !important;
    height: 50% !important;
  }

  .paragraph, .video, .nav-container {
    margin-left: 15vw;
    margin-right: 15vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar-brand>a>img {
    width: auto;
    max-height: 60% !important;
  }

  .nav-item {
    padding-bottom: 1em;
    padding-top: 1em;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media screen and (min-width: 481px) and (max-width: 767px) {

  .video {
    max-height: 50%;
    height: 50%;
  }

  .paragraph, .video, .nav-container {
    margin-left: 10vw;
    margin-right: 10vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .titleHeader {
    padding-top: 5% !important;
  }

  #main-nav {
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media screen and (min-width: 320px) and (max-width: 480px) {

  .video {
    max-height: 50%;
  }

  .paragraph, .video, p{
    margin-left:0.2em !important;
    margin-right:0.2em !important;
    width: 95%;
    max-width: 95%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    left: 0;
    right: 0;
  }

  .button {
    padding: calc(6vh + 6vw) calc(3vh + 3vw);
    font-size: 1.5em;
  }

  .infobox {
    margin-left: 0;
    margin-right: 0;
  }

  .titleHeader {
    padding-top: 5% !important;
  }

  #main-nav {
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }

  .listContainer {
    margin: 10px;
    text-align: justify;
  }
}

@media screen and (max-width: 685px) {
  .navbar-brand>a>img {
    width: auto;
    max-height: 40% !important;
  }
}